import { useCallback } from "react";

import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { ShareEmbed } from "@/modules/board/share/shareEmbed/ShareEmbed";
import { ShareUsers } from "@/modules/board/share/shareUsers/ShareUsers";
import { Box, Modal, Tabs } from "@/shared/system";
import { ModalHeader } from "@/shared/system/Modal/Modal";
import { WithId } from "@/types/db";

type Props = {
	project: WithId<ProjectDtoBoard>;
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const ShareModal: React.FC<Props> = ({ open, setOpen, project }) => {
	const handleModalClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal isOpen={open} onClose={setOpen}>
			<TabRoot>
				<ModalHeader>
					Share <span>{project?.data.name}</span>
				</ModalHeader>
				<Tabs defaultTab={1}>
					<Box label="Invite others">
						<ShareUsers project={project} modalClose={handleModalClose} />
					</Box>
					<Box label={`Embed`}>
						<ShareEmbed project={project} modalClose={handleModalClose} />
					</Box>
				</Tabs>
			</TabRoot>
		</Modal>
	);
};

const TabRoot = styled.div`
	[role="tablist"] {
		margin-bottom: 16px;
	}

	[role="tab"][aria-selected="true"] {
		border-bottom: 3px solid var(--color-accent-hover);
		padding-bottom: 9px;
	}

	[role="tabcontent"] {
		padding-top: 0;
		padding-bottom: 0;
		flex-grow: 1;
		overflow: auto;
	}
`;
